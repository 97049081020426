import React from 'react'
import Modal from 'react-modal'

import Context from '../state/Context'
import ActionBar from '../ActionBar'
import Player from '../Player'

// for accessibility (screen readers), see react-modal docs
Modal.setAppElement('#___gatsby') // gatsby v2 root element

const ALLOW_COOKIES_KEY = 'ac'

export default class RootLayout extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    const allowCookies = localStorage.getItem(ALLOW_COOKIES_KEY)
    if (!allowCookies) {
      this.setState({
      })
    }
  }
  componentDidUpdate(prevProps) {
  }
  render() {
    const { location, navigate } = this.props
    const routerProps = { location, navigate }
    const barStyle = actionBarStyle(location.pathname)
    // console.log('root render... props:', this.props)
    return (
      <Context {...this.props}>
        <ActionBar
          routerProps={routerProps}
          barStyle={barStyle}
          menuItems={menuItems}
        />
        {this.props.children}
        <Player />
      </Context>
    )
  }
}

function actionBarStyle(path) {
  const cleanPath =
    path !== '/' && path.endsWith('/') ? path.slice(0, -1) : path
  if (pathsWithBarOverlay.find(path => path === cleanPath)) {
    return 'overlay'
  } else {
    return 'default'
  }
}

const pathsWithBarOverlay = [
  '/',
  '/broadcaster/overview',
  '/about',
  '/get-radio',
  '/broadcaster-resources',
  '/podcast-hosting',
  '/christmas-music',
  '/gospel-music',
  '/jazz-music',
  '/classical-music',
  '/shaq'
]

const menuItems = [
  { value: '/broadcaster/overview', label: 'Start Your Station' },
  { value: '/listen', label: 'Listen to Radio' },
  { value: '/broadcaster-resources', label: 'Broadcaster Resources' },
  { value: 'https://help.live365.com/', label: 'Support' },
  { value: 'https://live365.com/blog', label: 'Blog' }
]
