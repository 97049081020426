import React from 'react'

import { SearchContextProvider } from './SearchContext'
import { PlayerContextProvider } from './PlayerContext'

// this component wraps each page and does not re-render on route changes
// (functionality provided by `gatsby-plugin-layout` in gatsby-config)
export default ({ children, ...otherProps }) => (
  <PlayerContextProvider {...otherProps}>
    {otherProps.location.pathname.includes('/listen') ? (
      <SearchContextProvider {...otherProps}>{children}</SearchContextProvider>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    )}
  </PlayerContextProvider>
)
