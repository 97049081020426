import React from 'react'
import AnimatedModal from '../../old/components/AnimatedModal'
import fetch from 'cross-fetch'

const countriesWithLicensing = [
  'US',
  'AS',
  'GU',
  'MP',
  'PR',
  'UM',
  'VI',
  'CA',
  'GB',
  'IM'
]

const defaultContextValue = {
  session: {
    isLoading: false,
    isPlaying: false,
    stationID: null,
    stationURL: null,
    metadata: null
  },
  updateSession: () => {}
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class PlayerContextProvider extends React.Component {
  constructor() {
    super()
    this.audioElement = React.createRef()
    this.updateSession = this.updateSession.bind(this)
    this.state = {
      ...defaultContextValue,
      updateSession: this.updateSession,
      streamingDisabled: false,
      isModalOpen: false
    }
  }
  componentDidMount() {
    this.setStreamingPermissions()
  }
  componentWillUnmount() {
    clearInterval(this.loadMetadataIntervalID)
  }
  async setStreamingPermissions() {
    try {
      const response = await fetch('https://api.live365.com/geocheck')
      const json = await response.json()
      const isValidCountry = countriesWithLicensing.includes(json.country)
      if (!isValidCountry) {
        console.log('STREAMING DISABLED')
        this.setState({ streamingDisabled: true })
      } else {
        console.log('STREAMING ENABLED')
      }
    } catch (error) {
      console.error('setStreamingPermissions error:', error)
    }
  }
  async loadMetadata(stationID) {
    try {
      const cacheBuster = '?cache=' + makeRandomString()
      const response = await fetch(
        `https://api.live365.com/station/${stationID}${cacheBuster}`
      )
      const metadata = await response.json()
      this.setState(prevState => ({
        ...prevState,
        session: {
          ...prevState.session,
          metadata
        }
      }))
    } catch (error) {
      console.error('loadMetadata error:', error)
    }
  }
  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
        <audio
          id="player-audio-element"
          src=""
          preload="metadata"
          ref={this.audioElement}
        />
        <AnimatedModal
          isOpen={this.state.isModalOpen}
          onRequestClose={() => this.setState({ isModalOpen: false })}
        >
          <p>Sorry, streaming is unavailable in your country.</p>
        </AnimatedModal>
      </Provider>
    )
  }
  // this is the API for managing player state
  // provide a station mount ID to update the session (start, pause, or resume)
  updateSession({ stationID, stationURL }) {
    console.log('UPDATE SESSION')
    if (this.state.streamingDisabled) {
      this.setState({ isModalOpen: true })
      return
    }
    const isNewSession = this.state.session.stationID !== stationID
    const isOnStationPage = this.props.location.pathname.includes(stationID)
    const isProStation = stationID.startsWith('b')
    if (isNewSession && !isOnStationPage) {
      this.props.navigate(stationURL)
    }
    if (!isProStation) {
      if (isNewSession) {
        this._startSession({ stationID, stationURL })
      } else {
        if (this.state.session.isPlaying) {
          this._pauseSession()
        } else {
          this._resumeSession()
        }
      }
    }
  }
  // the following methods are for internal use only
  // for external usage, see `updateSession`
  _startSession({ stationID, stationURL }) {
    console.log('START SESSION')
    // clear any previous metadata polling
    clearInterval(this.loadMetadataIntervalID)
    // immediately load metadata
    this.loadMetadata(stationID)
    // setup new metadata polling
    this.loadMetadataIntervalID = setInterval(
      () => this.loadMetadata(stationID),
      3000
    )
    this.setState(prevState => ({
      session: {
        ...prevState.session,
        stationID: stationID,
        stationURL: stationURL,
        isLoading: true
      }
    }))
    this.audioElement.current.src = makeStreamURL(stationID)
    this.audioElement.current
      .play()
      .then(response => {
        this.setState(prevState => ({
          session: {
            ...prevState.session,
            isLoading: false,
            isPlaying: true
          }
        }))
      })
      .catch(error => {
        console.log('no play', error)
      })
  }
  _pauseSession() {
    console.log('PAUSE SESSION')
    this.setState(prevState => {
      return {
        session: {
          ...prevState.session,
          isPlaying: false
        }
      }
    })
    this.audioElement.current.pause()
    this.audioElement.current.src = ''
  }
  _resumeSession() {
    console.log('RESUME SESSION')
    this.setState(prevState => ({
      session: {
        ...prevState.session,
        isLoading: true
      }
    }))
    this.audioElement.current.src = makeStreamURL(this.state.session.stationID)
    this.audioElement.current
      .play()
      .then(response => {
        this.setState(prevState => ({
          session: {
            ...prevState.session,
            isLoading: false,
            isPlaying: true
          }
        }))
      })
      .catch(error => {
        console.log('no play', error)
      })
  }
}

function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

function makeStreamURL(mountID) {
  const cacheBuster = '&cache=' + makeRandomString()
  var GDPRConsent = 'aw_0_req.gdpr=' + JSON.parse(getCookieValue('consent-is'))['gdpr']
  return 'https://streaming.live365.com/' + mountID + '?' + GDPRConsent + cacheBuster
}


function makeRandomString() {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  return text
}

export { Consumer as default, PlayerContextProvider }
