import React from 'react'

import PlayerContextConsumer from './state/PlayerContext'

import OldPlayer from '../old/components/Player'

// note that this component is only a consumer of the player state
// see `PlayerContext` for player functionality (including the audio element)
export default () => (
  <PlayerContextConsumer>
    {({ session, updateSession }) => {
      if (session.stationID) {
        return (
          <OldPlayer
            session={session}
            updateSession={updateSession}
            stationURL={session.stationURL}
          />
        )
      } else {
        return null
      }
    }}
  </PlayerContextConsumer>
)
