import React from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import qs from 'qs'

const defaultContextValue = {
  search: {
    query: null,
    menu: null
  },
  updateSearch: () => {}
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

class SearchContextProvider extends React.Component {
  constructor() {
    super()
    this.audioElement = React.createRef()
    this.updateSearch = this.updateSearch.bind(this)
    this.state = {
      ...defaultContextValue,
      updateSearch: this.updateSearch
    }
  }
  componentDidMount() {
    // console.log(
    //   'search context mounted: with location search:',
    //   this.props.location.search
    // )
    // restore search state when a URL with query is visited directly
    if (this.props.location.search) {
      const search = searchForQueryString(this.props.location.search)
      this.setState({ search })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log(
    //   'search context updated:',
    //   prevState.search,
    //   '->',
    //   this.state.search
    // )
    const didLocationSearchChange =
      prevProps.location.search !== this.props.location.search
    // restore search state when navigation occurs via back/forward buttons
    if (didLocationSearchChange) {
      // console.log(
      //   'location updated:',
      //   prevProps.location,
      //   '->',
      //   this.props.location
      // )
      let search
      // does location state contain text query or genre menu data?
      const isLocationStateValid =
        this.props.location.state.query || this.props.location.state.menu
      // try using location state, otherwise use the new query string
      if (isLocationStateValid) {
        // console.log(
        //   'restore search state from location state:',
        //   this.props.location.state
        // )
        search = this.props.location.state
      } else {
        search = searchForQueryString(this.props.location.search)
        // console.log('restore search state from new query string:', search)
      }
      this.setState({
        search: search
      })
    }
  }
  render() {
    return (
      <Provider value={this.state}>
        <InstantSearch
          appId="KQVZPNW1F5"
          apiKey="c93a53811be9eeca55ab4e78dec47045"
          indexName="stations"
          searchState={this.state.search}
          onSearchStateChange={search => this.updateSearch({ search })}
          createURL={queryStringForSearch}
        >
          {this.props.children}
        </InstantSearch>
      </Provider>
    )
  }
  // Algolia's InstantSearch component updates search state with this
  updateSearch({ search }) {
    // console.log('algolia updated search state:', search)
    // ensure this is a valid state update
    const isValidQuery = search.query !== undefined
    const isValidMenu = search.menu !== undefined
    if (!isValidQuery && !isValidMenu) {
      // console.log('invalid search state!')
      return
    }
    const onListenPage = this.props.location.pathname.includes('/listen')
    const didQueryChange = this.state.search.query !== search.query
    const didMenuChange = this.state.search.menu !== search.menu
    const shouldUpdate =
      (didQueryChange && isValidQuery) || (didMenuChange && isValidMenu)
    if (shouldUpdate) {
      // console.log('committing search update')
      this.setState({ search })
      if (onListenPage) {
        // console.log('updating URL')
        this.updateURLForSearch({ search, didQueryChange, didMenuChange })
      }
    }
  }
  updateURLForSearch({ search, didQueryChange, didMenuChange }) {
    let updateURLDelay = 0
    if (didQueryChange) {
      updateURLDelay = 750
    }
    if (this.searchUpdateTimeoutID) {
      clearTimeout(this.searchUpdateTimeoutID)
    }
    // don't update URL immediately after keystroke
    this.searchUpdateTimeoutID = setTimeout(
      function() {
        const queryString = queryStringForSearch(search)
        const fullURL = '/listen' + queryString
        // console.log('navigating to:', fullURL)
        this.props.navigate(fullURL)
      }.bind(this),
      updateURLDelay
    )
  }
}

function queryStringForSearch(search) {
  var params = []
  if (search.query) {
    params.push(qs.stringify({ query: search.query }))
  }
  if (search.menu && search.menu['genres.name']) {
    params.push(qs.stringify({ genre: search.menu['genres.name'] }))
  }
  var queryString = ''
  if (params.length > 0) {
    queryString = '?'
    params.forEach(param => (queryString += param + '&'))
    queryString = queryString.substring(0, queryString.length - 1)
  }
  // console.log('query string:', queryString, 'for search:', search)
  return queryString
}

function searchForQueryString(queryString) {
  const rawState = qs.parse(queryString, { ignoreQueryPrefix: true })
  const search = {
    menu: {
      'genres.name': rawState.genre || ''
    },
    query: rawState.query || '',
    page: rawState.page || 1
  }
  // console.log('search:', search, 'for query string:', queryString)
  return search
}

export { Consumer as default, SearchContextProvider }
