import React from 'react'
import classNames from 'classnames'

import PlayerContextConsumer from '../../components/state/PlayerContext'
import PlayingIndicator from './PlayingIndicator'

import './play-button.css'

export default ({ stationID, stationURL }) => {
  return (
    <PlayerContextConsumer>
      {({ session, updateSession }) => (
        <button
          className={classNames({
            'play-button': true
          })}
          onClick={() => {
            updateSession({ stationID, stationURL })
          }}
        >
          <PlayingIndicator isPlaying={isPlaying(session, stationID)} />
        </button>
      )}
    </PlayerContextConsumer>
  )
}

function isPlaying(session, stationID) {
  const isCurrentSession = session.stationID === stationID
  return isCurrentSession && (session.isPlaying || session.isLoading)
}
