import React from 'react'
import { Link } from 'gatsby'
import Toggle from 'react-toggled'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import classNames from 'classnames'

import PlayButton from './PlayButton'

import './player.css'

class Player extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      volume: 0.5,
      showVolumeSlider: false
    }

    this.playerElement = React.createRef()
    this.volumeControl = React.createRef()
    this.handleChangeVolume = this.handleChangeVolume.bind(this)
    this.handleBlurVolumeControl = this.handleBlurVolumeControl.bind(this)
  }

  componentDidMount() {
    document.getElementById('player-audio-element').volume = this.state.volume
  }

  handleChangeVolume(value) {
    this.setState({ volume: value })
    document.getElementById('player-audio-element').volume = value
  }

  handleBlurVolumeControl(event) {
    if (!this.playerElement.current.contains(event.relatedTarget)) {
      this.setState({ showVolumeSlider: false })
    }
  }

  render() {
    const metadata = this.props.session.metadata
    return (
      <div className="player light-on-dark" ref={this.playerElement}>
        <div className={classNames('bar-container', 'alignment-container')}>
          {metadata && (
            <div className={classNames('metadata')}>
              <Link
                to={this.props.session.stationURL}
                className={classNames('station-info')}
              >
                <img
                  className={classNames('player-image')}
                  src={metadata['station-logo']}
                  alt={metadata['name']}
                />
                <p
                  className={classNames('station-name')}
                  title={metadata['name']}
                >
                  {metadata['name']}
                </p>
              </Link>

              <div className="media-info">
                <p
                  className="track-name"
                  title={metadata['current-track'].title}
                >
                  {metadata['current-track'].title}
                </p>
                <p className="track-artist">
                  {metadata['current-track'].artist}
                </p>
              </div>

              <img
                className={classNames('media-art', 'player-image')}
                alt="media"
                src={metadata['current-track'].art}
              />
            </div>
          )}

          <div className="controls">
            <PlayButton
              stationID={this.props.session.stationID}
              playerSession={this.props.session}
              updatePlayerSession={this.props.updateSession}
            />

            <div className={classNames('time')}>
              <div className={classNames('time-elapsed')}>
                <time>LIVE</time>
              </div>

              <div className={classNames('timeline')} />
            </div>

            <Toggle
              on={this.state.showVolumeSlider}
              onToggle={on => this.setState({ showVolumeSlider: on })}
            >
              {({ on, getTogglerProps }) => (
                <div className={classNames('volume')} tabIndex="1" ref={this.volumeControl} onBlur={this.handleBlurVolumeControl}>
                  <button
                    className={classNames('volume-button', { on: on })}
                    {...getTogglerProps()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="16"
                    >
                      <path
                        fill="#FFF"
                        d="M8 6c1.105 0 2 .895 2 2s-.895 2-2 2v4.004c0 .55-.355.73-.8.396L4 12H2c-1.105 0-2-.894-2-1.998V5.998C0 4.895.898 4 2 4h2l3.2-2.4c.442-.33.8-.15.8.396V6zM4.667 6H2v4.002L4.667 10 6 11.03V4.99L4.667 6zm10.03 9.646C16.79 13.676 18 10.933 18 8c0-2.932-1.21-5.674-3.302-7.645-.402-.378-1.035-.36-1.414.043-.378.402-.36 1.035.043 1.413C15.022 3.41 16 5.628 16 8c0 2.376-.978 4.593-2.674 6.19-.402.378-.42 1.01-.043 1.413.38.402 1.012.42 1.414.043zm-2.765-2.89C13.242 11.533 14 9.826 14 8c0-1.8-.735-3.482-2.01-4.7-.4-.382-1.033-.367-1.414.032-.382.4-.367 1.032.032 1.414C11.492 5.59 12 6.753 12 8c0 1.266-.524 2.446-1.432 3.292-.404.377-.426 1.01-.05 1.414.377.403 1.01.426 1.414.05z"
                      />
                    </svg>
                  </button>

                  <div className={classNames('slider-container', { on: on })}>
                    <Slider
                      className={classNames('volume-slider')}
                      max={1.0}
                      step={0.01}
                      vertical
                      defaultValue={this.state.volume}
                      trackStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        width: '5px'
                      }}
                      handleStyle={{
                        borderColor: '#FFF',
                        backgroundColor: '#1286E9'
                      }}
                      railStyle={{
                        backgroundColor: 'rgba(255, 255, 255, .5)',
                        width: '5px'
                      }}
                      onChange={this.handleChangeVolume}
                    />
                  </div>
                </div>
              )}
            </Toggle>
          </div>
        </div>
      </div>
    )
  }
}

export default Player
