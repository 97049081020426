import React, { Component } from 'react'
import { SearchBox } from 'react-instantsearch-dom'
import classNames from 'classnames'
import qs from 'qs'

import './search-field.css'

class SearchField extends Component {
  constructor(props) {
    super(props)

    const queryString = qs.parse(props.location.search, {
      ignoreQueryPrefix: true
    })

    this.state = {
      query: queryString.query || ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({
      query: event.target.value || ''
    })
  }

  handleSubmit(event) {
    // prevent default submit event
    event.preventDefault()

    // redirect to listen page with search results
    if (!this.props.location.pathname.includes('/listen')) {
      const queryString = qs.stringify(this.state)
      this.props.navigate('/listen?' + queryString)
    }
  }

  render() {
    if (this.props.location.pathname.includes('/listen')) {
      return (
        <SearchBox
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          onReset={this.handleChange}
          submit={submitIcon}
          reset={resetIcon}
          translations={{
            placeholder: 'Search...'
          }}
          className={classNames(this.props.className, {
            // 'search-field': true,
            'ais-SearchBox--active': this.state.query.length > 0
          })}
        />
      )
    } else {
      return (
        <form
          onSubmit={this.handleSubmit}
          className={`${this.props.className} search-form`}
        >
          <input
            type="text"
            onChange={this.handleChange}
            onReset={this.handleChange}
            submit={submitIcon}
            reset={resetIcon}
            placeholder="Search..."
            className={classNames(this.props.className, {
              'search-field': true,
              'ais-SearchBox--active': this.state.query.length > 0
            })}
          />
          <button onClick={this.handleSubmit} className="search-button">
            {submitIcon}
          </button>
        </form>
      )
    }
  }
}

const submitIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(1.362 .362)"
      strokeWidth="2"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <circle cx="6.931" cy="7.335" r="6.523" />
      <path d="M11.735 11.735l5.172 5.172" strokeLinecap="round" />
    </g>
  </svg>
)

const resetIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(1.362 .362)"
      strokeWidth="2"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <path d="M 1.735,1.735 L 16.172,16.172" strokeLinecap="round" />
      <path d="M 16.172,1.735 L 1.735,16.172" strokeLinecap="round" />
    </g>
  </svg>
)

export default SearchField
