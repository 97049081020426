import React from 'react'

import PageLayout from './PageLayout'
import ForegroundLayout from './ForegroundLayout'
import FlexBox from './FlexBox'

const RowLayout = props => {
  const { children, ...otherProps } = props
  return (
    <FlexBox flexDirection={'row'} {...otherProps}>
      {children}
    </FlexBox>
  )
}

const ColumnLayout = props => {
  const { children, ...otherProps } = props
  return (
    <FlexBox flexDirection={'column'} {...otherProps}>
      {children}
    </FlexBox>
  )
}

export { PageLayout, ForegroundLayout, RowLayout, ColumnLayout }
