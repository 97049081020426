import React, { Component } from 'react'
import Dropdown from 'react-dropdown'

import './action-menu.css'

class ActionMenu extends Component {
  constructor(props) {
    super(props)

    this.actions = props.actions || ['No actions!']
  }

  render() {
    return (
      <Dropdown
        className="action-menu"
        options={this.actions}
        onChange={this.onSelect.bind(this)}
      />
    )
  }

  onSelect(action) {
    // determine if the selection belongs to this site's routes
    if (action.value.startsWith('http')) {
      // redirect to external link
      if (typeof window !== 'undefined') {
        window.location = action.value
      }
    } else {
      // update the history directly since Dropdown doesn't use router `Link`s
      this.props.navigate(action.value)
    }
  }
}

export default ActionMenu
