import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { ForegroundLayout, ColumnLayout, RowLayout } from './layout'
import SocialMediaLink from './SocialMediaLink'
import live365Logo from '../assets/images/live365-logo-gray.svg'

export default () => (
  <Wrapper>
    <ForegroundLayout lightOnDark>
      <ColumnLayout itemSpacing={'6rem'}>
        <RowLayout
          isResponsive
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Links>
            {links.map((link, index) => (
              <li key={index}>
                {link.external ? (
                  <StyledAnchor href={link.url}>{link.title}</StyledAnchor>
                ) : (
                  <StyledLink to={link.url}>{link.title}</StyledLink>
                )}
              </li>
            ))}
          </Links>
          <SocialMediaLinks>
            {socialMediaLinks.map((link, index) => (
              <li key={index}>
                <SocialMediaLink url={link.url} color={'#aaa'} />
              </li>
            ))}
          </SocialMediaLinks>
        </RowLayout>
        <RowLayout
          isResponsive
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Link to="/">
            <Logo src={live365Logo} alt="Live365 logo" />
          </Link>
          <RowLayout
            isResponsive
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Copyright>© 2020 Live365</Copyright>
            <LegalLinks>
              {legalLinks.map((link, index) => (
                <li key={index}>
                  <StyledLink to={link.url}>{link.title}</StyledLink>
                </li>
              ))}
            </LegalLinks>
          </RowLayout>
        </RowLayout>
      </ColumnLayout>
    </ForegroundLayout>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 4rem 0;
  width: 100%;
  background-color: #f1f1f1;
`

const Links = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 2;
  & > li {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
`

const StyledAnchor = styled.a`
  color: #666;
  font-weight: 600;
  text-decoration: none;
  transition: color 150ms linear;
  &:hover {
    color: #fd6923;
  }
`

const StyledLink = styled(Link)`
  color: #666;
  font-weight: 600;
  text-decoration: none;
  transition: color 150ms linear;
  &:hover {
    color: #fd6923;
  }
`

const SocialMediaLinks = styled(Links)`
  columns: 4;
  & > li {
    margin-right: 0;
  }
`

const Logo = styled.img`
  display: block;
  height: 42px;
`

const Copyright = styled.p`
  display: inline-block;
  padding-right: 2rem;
  color: #888;
`

const LegalLinks = styled(Links)`
  columns: 4;
  & > li {
    text-align: right;
    margin-bottom: 0;
    margin-right: 0;
  }
  & > li:last-child {
    margin-right: 0;
  }
`

const links = [
  {
    title: 'Podcast Hosting',
    url: '/podcast-hosting'
  },
  {
    title: 'Christmas Music',
    url: '/christmas-music'
  },
  {
    title: 'Where to Listen',
    url: '/get-radio'
  },
  {
    title: 'Broadcaster Resources',
    url: '/broadcaster-resources'
  },
  {
    title: 'About Us',
    url: '/about'
  },
  {
    title: 'Blog',
    url: 'https://live365.com/blog',
    external: true
  },
  {
    title: 'Careers',
    url: 'https://angel.co/live365',
    external: true
  },
  {
    title: 'Support',
    url: 'https://help.live365.com/',
    external: true
  }
]

const socialMediaLinks = [
  {
    title: 'Live365 Facebook',
    url: 'https://www.facebook.com/live365official/'
  },
  {
    title: 'Live365 Twitter',
    url: 'https://twitter.com/Live365'
  },
  {
    title: 'Live365 Instagram',
    url: 'https://www.instagram.com/live365official/'
  },
  {
    title: 'Live365 LinkedIn',
    url: 'https://www.linkedin.com/company/live365'
  }
]

const legalLinks = [
  {
    title: 'Terms',
    url: '/legal/terms'
  },
  {
    title: 'DMCA',
    url: '/legal/dmca'
  },
  {
    title: 'Privacy',
    url: '/legal/privacy'
  },
  {
    title: 'Cookies',
    url: '/legal/cookies'
  }
]
