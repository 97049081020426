import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'

function LinkButton(props) {
  return (
    <Button to={props.to} color={props.color} className={props.className}>
      {props.children}
    </Button>
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['orange', 'blue']),
  children: PropTypes.node.isRequired
}

const palette = {
  orange: '#fd6923',
  blue: '#1286e9'
}

const Button = styled(Link)`
  display: inline-block;
  border-radius: 4px;
  padding: 0.75rem 1rem 0.8rem;
  // height: 42px;
  background-color: ${props => palette[props.color] || palette.orange};
  color: #fff;
  font-weight: 800;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: ${props =>
    props.color === 'blue'
      ? '0 8px 32px rgba(22, 144, 202, 0.3)'
      : '0 8px 32px rgba(253, 105, 35, 0.3)'};
  transition: background-color 150ms linear;
  &:hover {
    background-color: ${props =>
      props.color === 'blue' ? '#0B78D1' : '#EB4D02'};
  }
`

export default LinkButton
