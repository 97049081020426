import styled from 'styled-components'

const ForegroundLayout = styled.div`
  z-index: 1;
  height: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    margin: 0 5% 0 5%;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    margin: 0 7% 0 7%;
  }
  @media (min-width: 1201px) {
    width: 100%;
  }
  -webkit-font-smoothing: ${props =>
    props.lightOnDark ? 'antialiased' : 'initial'};
  -moz-osx-font-smoothing: ${props =>
    props.lightOnDark ? 'grayscale' : 'initial'};
`

export default ForegroundLayout
