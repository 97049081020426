require('core-js/fn/number/is-nan')

const pixels = [
  { pathname: '/index', event: 'VisitLive365Homepage'},
  { pathname: '/broadcaster/overview', event: 'VisitBroadcasterPage'},
  { pathname: '/broadcaster/audience', event: 'VisitAudiencePage'},
  { pathname: '/broadcaster/station-management', event: 'VisitStationManagementPage'},
  { pathname: '/broadcaster/statistics', event: 'VisitStatisticsPage'},
  { pathname: '/broadcaster/licensing', event: 'VisitLicensingPage'},
  { pathname: '/broadcaster/monetization', event: 'VisitMonetizationPage'},
  { pathname: '/broadcaster/pricing', event: 'VisitPricingPage'},
  { pathname: '/listen', event: 'VisitListenPage'},
  { pathname: '/station', event: 'VisitStationPage'}
]

exports.onRouteUpdate = ({ location }) => {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    const pathname = location.pathname
    let event
    if (pathname === '/') {
      event = pixels.find(p => p.pathname === '/index')
    } else {
      // `startsWith` is used to support /station prefix (could use regex here)
      event = pixels.find(p => pathname.startsWith(p.pathname))
    }
    if (event) {
      // fbq('trackCustom', event)
    }
  }
};
