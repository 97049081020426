import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

import { ForegroundLayout, ColumnLayout } from './layout'

export default ({
  title,
  subtitle,
  lightOnDark,
  paddingTop,
  background,
  children
}) => {
  const useImage = background && typeof background.image === 'string'
  const useGatsbyImage = background && typeof background.image === 'object'
  const useColor = background && background.color
  const withOverlay =
    background && (background.withOverlay === false ? false : true) && !useColor
  return (
    <StyledHeader withOverlay={withOverlay}>
      {useImage && <ImageBackground src={background.image} />}
      {useGatsbyImage && (
        <GatsbyImageBackground
          fluid={background.image}
          style={{ position: 'absolute' }}
        />
      )}
      {useColor && <ColorBackground color={background.color} />}
      <ColumnLayout itemSpacing={'4rem'}>
        <PaddedForeground paddingTop={paddingTop} lightOnDark={lightOnDark}>
          <ColumnLayout alignItems={'center'} itemSpacing={'4rem'}>
            {title && <Title lightOnDark={lightOnDark}>{title}</Title>}
            {subtitle && (
              <Subtitle lightOnDark={lightOnDark}>{subtitle}</Subtitle>
            )}
            {children}
          </ColumnLayout>
        </PaddedForeground>
      </ColumnLayout>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: ${props => (props.withOverlay ? '0.33' : '0')};
  }
  @media (min-width: 769px) {
    min-height: 28rem;
  }
`

const ColorBackground = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: ${props => props.color || '#fff'};
  background-size: cover;
  background-position: center;
`

const GatsbyImageBackground = styled(GatsbyImage)`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const ImageBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-position: bottom;
  background-size: cover;
`

// need to use arrow function due to an unknown bug (styled components?)
const PaddedForeground = styled(props => <ForegroundLayout {...props} />)`
  @media (max-width: 768px) {
    padding: ${props => props.paddingTop || '8rem'} 0 4rem 0;
  }
  @media (min-width: 769px) {
    padding: ${props => props.paddingTop || '14rem'} 0 4rem 0;
  }
`

const Title = styled.h1`
  margin: 0;
  color: ${props => (props.lightOnDark ? '#fff' : '#222')};
  font-size: 2.25rem;
  font-weight: 800;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (min-width: 769px) {
    font-size: 2.25rem;
  }
`

const Subtitle = styled.h2`
  margin: 0;
  color: ${props => (props.lightOnDark ? '#eee' : '#444')};
  font-weight: 300;
  text-align: center;
`
