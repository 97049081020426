import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  )
}

const AnimatedModal = styled(ReactModalAdapter).attrs({
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close'
  },
  modalClassName: {
    base: 'Content',
    afterOpen: 'Content--after-open',
    beforeClose: 'Content--before-close'
  },
  closeTimeoutMS: 250
})`
  .Content {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    border-radius: 4px;
    padding: 2rem;
    background-color: #fff;
    opacity: 0;
    transform: translateY(1rem);
    &--after-open {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 250ms, transform 250ms ease-out;
    }
    &--before-close {
      opacity: 0;
      transform: translateY(1rem);
    }
    &:focus {
      outline: 0;
    }
  }
  .Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    &--after-open {
      opacity: 1;
      transition: opacity 250ms;
    }
    &--before-close {
      opacity: 0;
    }
  }
`

export default AnimatedModal
