import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { ForegroundLayout, RowLayout } from './layout'
import LinkButton from './LinkButton'
import live365Logo from '../assets/images/live365-logo-white.svg'
import userIcon from '../assets/icons/user.svg'
// import moreIcon from '../assets/icons/more.svg'

import SearchField from '../old/components/SearchField'
import ActionMenu from '../old/components/ActionMenu'

export default class ActionBar extends React.Component {
  constructor() {
    super()
    this.state = {
      isSearchVisible: false
    }
    this.toggleSearchField = this.toggleSearchField.bind(this)
  }
  render() {
    const { routerProps, barStyle, menuItems } = this.props
    return (
      <Bar barStyle={barStyle}>
        <ForegroundLayout lightOnDark>
          <RowLayout
            alignItems={'center'}
            justifyContent={'space-between'}
            itemSpacing={'2rem'}
          >
            <MobileSearchToggle onClick={this.toggleSearchField}>
              {searchIcon}
            </MobileSearchToggle>
            <HomeLink to="/" isvisible={(!this.state.isSearchVisible).toString()}>
              <Logo src={live365Logo} alt="Live365 logo" />
            </HomeLink>
            <ResponsiveSearchField
              location={routerProps.location}
              navigate={routerProps.navigate}
              isvisible={this.state.isSearchVisible.toString()}
            />
            <RowLayout
              alignItems={'center'}
              justifyContent={'flex-end'}
              itemSpacing={'2rem'}
              style={{ width: 'unset', flex: 'initial' }}
            >
              {!routerProps.location.pathname.includes(
                '/broadcaster/overview'
              ) && (
                <CallToActionButton to="/broadcaster/overview">
                  Start Your Station
                </CallToActionButton>
              )}
              <BroadcasterLoginLink href="https://dashboard.live365.com/">
                <img src={userIcon} alt="Log In" />
              </BroadcasterLoginLink>
              <ActionMenu actions={menuItems} navigate={routerProps.navigate} />
            </RowLayout>
          </RowLayout>
        </ForegroundLayout>
      </Bar>
    )
  }
  toggleSearchField() {
    this.setState({
      isSearchVisible: !this.state.isSearchVisible
    })
  }
}

const Bar = styled.div`
  position: ${props => {
    switch (props.barStyle) {
      case 'overlay':
        return 'absolute'
      default:
        return 'relative'
    }
  }};
  z-index: 100;
  padding: ${props => {
    switch (props.barStyle) {
      case 'overlay':
        return '7rem 0'
      default:
        return '0'
    }
  }};
  width: 100%;
  height: 75px;
  background-color: ${props => {
    switch (props.barStyle) {
      case 'overlay':
        return 'transparent'
      default:
        return '#080808'
    }
  }};
  @media (max-width: 768px) {
    padding: 0;
    height: 60px;
    background-color: #080808;
  }
`

const MobileSearchToggle = styled.button`
  border: 0;
  background-color: transparent;
  opacity: 0.5;
  @media (min-width: 769px) {
    display: none;
  }
`

const HomeLink = styled(Link)`
  flex: none;
  @media (max-width: 768px) {
    display: ${props => (props.isvisible === 'true' ? 'block' : 'none')};
  }
  @media (min-width: 769px) {
    min-width: 134px;
  }
`

const Logo = styled.img`
  display: block;
  max-width: none;
  height: 42px;
  @media (max-width: 768px) {
    height: 30px;
  }
`

const ResponsiveSearchField = styled(SearchField)`
  @media (max-width: 768px) {
    display: ${props => (props.isvisible === 'true' ? 'block' : 'none')};
  }
`

const CallToActionButton = styled(LinkButton)`
  flex-shrink: 0;
  @media (max-width: 900px) {
    display: none;
  }
`

const BroadcasterLoginLink = styled.a`
  flex-shrink: 0;
  opacity: 0.5;
  transition: opacity 150ms linear;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 900px) {
    display: none;
  }
`

const searchIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(1.362 .362)"
      strokeWidth="2"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <circle cx="6.931" cy="7.335" r="6.523" />
      <path d="M11.735 11.735l5.172 5.172" strokeLinecap="round" />
    </g>
  </svg>
)
