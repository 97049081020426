import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './playing-indicator.css'

export default function PlayingIndicator(props) {
  return (
    <div
      className={classNames({
        'playing-indicator': true,
        'is-playing': props.isPlaying
      })}
    >
      {props.isPlaying ? pauseIcon : playIcon}
    </div>
  )
}

PlayingIndicator.propTypes = {
  isPlaying: PropTypes.bool
}

const playIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path fill="#FFF" fillRule="evenodd" d="M0 0l16 8-16 8" />
  </svg>
)

const pauseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path
      fill="#FFF"
      d="M6.063 0H1.27C1.007 0 .793.15.793.333v15.334c0 .184.214.333.48.333h4.79c.265 0 .48-.15.48-.333V.333C6.543.15 6.327 0 6.063 0zm8.667 0H9.937c-.265 0-.48.15-.48.333v15.334c0 .184.215.333.48.333h4.79c.266 0 .48-.15.48-.333V.333c0-.184-.214-.333-.48-.333z"
    />
  </svg>
)
