import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import Footer from '../Footer'

import 'sanitize.css'
import '../../assets/fonts.css'
import '../../assets/styles.css'

const PageLayout = ({ title, subtitle, header = {}, children }) => (
  <React.Fragment>
    <Header
      title={title}
      subtitle={subtitle}
      lightOnDark={header.lightOnDark}
      barStyle={header.barStyle}
      paddingTop={header.paddingTop}
      background={header.background}
      showCallToActionButton={true}
    >
      {header.children}
    </Header>
    {children}
    <Footer />
  </React.Fragment>
)

PageLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  header: PropTypes.shape({
    barStyle: PropTypes.oneOf(['overlay', 'default']),
    backgroundImage: PropTypes.string,
    children: PropTypes.node
  }),
  children: PropTypes.node.isRequired
}

export default PageLayout
